import { createI18n } from "vue-i18n";
import fr from "@/portal/i18n/locales/fr.json";
import en from "@/portal/i18n/locales/en.json";

const i18n = createI18n({
  allowComposition: true,
  fallbackLocale: "en",
  flatJson: true,
  messages: {
    fr,
    en,
  },
});

export { i18n };
export default i18n.global;
